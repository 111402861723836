<template>
  <div style="width: 100%; height: 100%" id="pdfBox" class="fadeIn">
    <div class="iotName">
      <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="$router.push('/DocumentationList')"
      >
        <!-- <template #right>
          <van-icon @click="download" v-if="check == 0" name="down" size="16" />
        </template> -->
      </van-nav-bar>
    </div>
    <div style="height: calc(100% - 46px); overflow-y: auto">
      <div v-if="check == 1" class="videoBox">
        <div
          v-for="item in videsList"
          @click="videoDialog(item)"
          :key="item.workId"
          class="videoImg"
        >
          <div style="width: 100%; height: calc(100% - 20px)">
            <img
              :src="workUrl + item.workImg"
              style="width: 100%; height: 100%; border-radius: 12px"
              alt=""
            />
          </div>
          <div class="videoName">{{ item.name }}</div>
        </div>
      </div>
      <div :style="{ width: boxWidth, zoom: zoom }" style="overflow: hidden">
        <pdf v-for="i in numPages" :key="i" :src="url" :page="i"></pdf>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { workUrl } from "../util/global.js";
import pdf from "vue-pdf";
let that;
export default {
  data() {
    return {
      boxWidth: "370px",

      show: false,
      zoom: 1,
      currentPage: 0,
      pageCount: 0,
      workUrl: workUrl,
      workId: "",
      url: "",
      videsList: [],
      check: 0,
      numPages: 0,
      displacement: [],
      pdfUrl: "",
      pdfName: "",
    };
  },
  components: {
    pdf,
  },
  mounted() {
    that = this;
    that.boxWidth = window.innerWidth - 10 + "px";
    that.workId = that.$route.query.workId;
    document.title = that.$route.query.title;
    wx.ready(function () {
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: that.$route.query.title, // 分享标题
        desc: that.$route.query.title, // 分享描述
        link: window.location.href, // 分享链接
        imgUrl:
          "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功
        },
      });
      wx.updateTimelineShareData({
        title: that.$route.query.title, // 分享标题
        desc: that.$route.query.title, // 分享描述
        link: window.location.href, // 分享链接
        imgUrl:
          "https://oss.cniot.fun/factory-platform/test/image/platform/20220316141425508.jpg", // 分享图标
        success: function () {
          // 设置成功
        },
      });
    });
    that.getBox();
  },
  methods: {
    download() {
      window.open(that.pdfUrl);
    },
    delVideo() {
      let videos = document.getElementById("videos");
      videos.pause();
      that.show = false;
    },
    videoDialog(item) {
      that.$router.push(
        `/video?workOrder=${item.workOrder}&title=${item.name}`
      );
    },
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url);

      loadingTask.promise
        .then((item) => {
          that.url = loadingTask;
          that.numPages = item.numPages;
        })
        .catch((err) => {
          console.error("pdf加载失败");
        });
    },

    // 获取右侧说明或视频内容
    getBox() {
      that.url = "";
      that.pdfUrl = "";
      let arr = that.workId.split("-");
      that.check = arr[1];
      if (arr[1] == 0) {
        that.$api.workOrder.getWorkOrder(arr[0]).then((res) => {
          if (res.code == 200) {
            if (res.data.workOrder) {
              //   that.url = ;
              that.getNumPages(workUrl + res.data.workOrder);
              that.pdfUrl = workUrl + res.data.workOrder;
              let dom = document.getElementById("pdfBox");
              dom.addEventListener("touchstart", function (event) {
                that.displacement = event.touches;
              });
              dom.addEventListener("touchmove", function (event) {
                if (that.displacement.length < 2) {
                  return;
                }
                event.preventDefault();
                if (event.touches[1]) {
                  let zoom =
                    Math.hypot(
                      event.touches[0].pageX - event.touches[1].pageX,
                      event.touches[0].pageY - event.touches[1].pageY
                    ) /
                    Math.hypot(
                      that.displacement[0].pageX - that.displacement[1].pageX,
                      that.displacement[0].pageY - that.displacement[1].pageY
                    );
                  zoom = Math.floor(zoom * 100) / 100;
                  if (zoom < 1) {
                    zoom = 1;
                  }
                  that.zoom = zoom;
                }
              });
              dom.addEventListener("touchend", function (event) {
                that.displacement = [];
              });
            } else {
              that.url = "";
            }
          }
        });
      } else if (arr[1] == 1) {
        that.$api.workOrder.getWorkOrderList(arr[0]).then((res) => {
          if (res.code == 200) {
            that.videsList = res.data;
          }
        });
      }
    },
  },
  beforeDestroy() {
    // that.delVideo();
  },
};
</script>
<style scoped>
.videoName {
  /* text-align:center; */
  font-size: 14px;
  color: #666;
  box-sizing: border-box;
  font-weight: bold;
  padding: 5px 5px;
  line-height: 20px;
}
.videoImg {
  width: 40%;
  height: 25vw;
  margin: 3% 5%;
  cursor: pointer;
  border-radius: 12px;
  background: #ffffff;
}
.videoBox {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
}
.videoBox::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.videoBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}
</style>
